<template>
  <span :class="{ 'w-full md:w-auto': isLanderVariant }">
    <Modal v-model="isModalVisible" :click-to-close="step == 'confirm'">
      <div class="content text-body">
        <template v-if="step == 'qualify'" class="blue">
          <h1>There's one more thing...</h1>
          <p>The information you provide below will really help us decide who to reach out to first!</p>
          <p v-if="isReferred">Oh, and don't worry. When you've been invited to sign up, you'll still receive <strong>{{ referrerOrganization.referralDiscount }}</strong> thanks to {{ referrerOrganization.name }}'s invite!</p>
          <form>
            <input
              id="name-input"
              v-model="lead.name"
              type="text"
              placeholder="Your name"
              :class="['form-input mb-2 w-full', { 'border-danger': isInputNameInvalid }]"
              autofocus
            >
            <textarea
              id="comments-input"
              v-model="lead.comments"
              placeholder="What are your current problems and challenges that you're hoping to solve with Trunk?"
              :class="['form-input w-full h-32', { 'border-danger': isInputCommentsInvalid }]"
            />
            <Button
              :is-loading="isLeadUpdating"
              classes="btn-primary mt-4 w-full text-center md:w-auto md:text-left"
              @click.native="updateLead"
            >
              Submit
            </Button>
          </form>
        </template>
        <template v-else-if="step == 'confirm'">
          <h1>Almost done!</h1>
          <p>A link has just been sent to <strong>{{ lead.email }}</strong>.</p>
          <p class="mb-0">
            You'll need to <strong>click on it</strong> to confirm your email so that we'll be able to reach you. Make sure to check your spam folder (and mark it as <em>Not spam</em>)!
          </p>
        </template>
      </div>
    </Modal>
    <div v-if="isEmailCaptureVisible" class="flex flex-col md:flex-row">
      <input
        ref="emailInput"
        v-model="lead.email"
        type="email"
        placeholder="Your email address"
        :class="['form-input rounded-b-none md:rounded-r-none md:rounded-l border-b-0 md:border-b md:border-r-0', { 'md:w-56': !isLanderVariant, 'form-input-lg flex-grow': isLanderVariant }]"
        @keyup.enter="createLead"
      ><Button
        :class="['btn btn-primary rounded-t-none md:rounded-l-none md:rounded-r', { 'btn-lg w-full text-center md:w-auto md:text-left': isLanderVariant }]"
        :is-loading="isLeadCreating"
        @click.native="createLead"
      >
        Next
      </Button>
    </div>
    <Button
      v-else
      :data-test-cta="variant"
      :class="{ 'btn-lg w-full text-center md:w-auto md:text-left': isLanderVariant }"
      :id="variant + '-cta-trigger'"
      @click.native="triggerCta"
    >
      <span v-if="!isRequestAccessVisible">Get started</span><span v-else>Request access</span>
    </Button>
  </span>
</template>

<script>
  import axios from 'axios'

  import { getAuthenticityToken, isBlank } from '@common/helpers'

  import Modal from '@application/components/modal.vue'
  import Button from '@application/components/button.vue'

  export default {
    components: {
      Button,
      Modal,
    },

    props: {
      areSignupsOpen: Boolean,
      variant: {
        type: String,
        default: null,
      },
    },

    data() {
      return {
        step: null,
        lead: {},
        isLeadCreating: false,
        isLeadUpdating: false,
        isModalVisible: false,
        isEmailCaptureVisible: false,
        isInputNameInvalid: false,
        isInputCommentsInvalid: false,

        // If organization is referring current lead, this object will exist
        referrerOrganization: window.referrerOrganization,
      }
    },

    computed: {
      isLanderVariant() {
        return this.variant == 'lander'
      },

      isReferred() {
        return this.referrerOrganization != undefined
      },

      isRequestAccessVisible() {
        return !this.isReferred && !this.areSignupsOpen
      },
    },

    watch: {
      isModalVisible(value) {
        // Reset lead if modal is closed
        if (value === false) {
          this.lead = {}

          this.$emit('close-modal')
        }
      },
    },

    methods: {
      triggerCta() {
        if (this.isRequestAccessVisible) {
          window.analytics.track('Request Access Clicked', { variant: this.variant })
          window.open('https://trunkinventory.typeform.com/to/q1K47v')
        } else {
          window.analytics.track('Sign Up Clicked', { variant: this.variant })

          if (this.isReferred) {
            window.open(`/start?referral=${window.referrerOrganization.referralCode}`)
          } else {
            window.open(`/start`)
          }
        }
      },

      showEmailCapture() {
        this.isEmailCaptureVisible = true

        this.$emit('activate')

        this.$nextTick(() => {
          this.$refs.emailInput.focus()
        })

        window.analytics.track('Lead Capture Activated', { variant: this.variant })
      },

      async createLead() {
        this.isLeadCreating = true

        const lead = this.lead

        if (this.isReferred) {
          lead['referrer_organization_id'] = this.referrerOrganization.id
        }

        const response = await axios.post('/leads', {
          lead,

          authenticity_token: getAuthenticityToken(),
        })

        this.isModalVisible = true
        this.isLeadCreating = false
        this.lead.id = response.data.data.id

        this.step = 'qualify'

        this.isEmailCaptureVisible = false
      },

      async updateLead() {
        // Validate that inputs are not blank
        this.isInputNameInvalid = isBlank(this.lead.name)
        this.isInputCommentsInvalid = isBlank(this.lead.comments)

        if (this.isInputNameInvalid || this.isInputCommentsInvalid) {
          return
        }

        this.isLeadUpdating = true

        await axios.patch(`/leads/${this.lead.id}`, {
          lead: this.lead,
          authenticity_token: getAuthenticityToken(),
        })

        this.isLeadUpdating = false
        this.step = 'confirm'
      },
    },
  }
</script>
