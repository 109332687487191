/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Modules
import '@application/modules/vue.js'

// FontAwesome
import '@fortawesome/fontawesome-pro/css/fontawesome.css'
import '@fortawesome/fontawesome-pro/css/light.css'
import '@fortawesome/fontawesome-pro/css/regular.css'
import '@fortawesome/fontawesome-pro/css/solid.css'

// Styles
import '@application/styles/vendor/tailwind.scss'
import '@common/styles/modules/fonts/avenir-next.scss'
import '@application/styles/modules/base.scss'
import '@application/styles/modules/components.scss'
import '@application/styles/modules/typography.scss'
import '@application/styles/modules/layout.scss'
import '@application/styles/modules/pages.scss'

// Images
require.context('@application/images', true, /\.*$/)
require.context('@common/images', true, /\.*$/)
