<template>
  <span>
    <div ref="header" class="header fixed pin-t w-full top-0 z-10 bg-white">
      <div class="md:hidden bg-primary h-1" />
      <a :href="routes.affiliatesPath" class="block text-center bg-primary text-white h-12 leading-12 cursor-pointer hidden md:block">
        Interested in earning 20% recurring commissions? Join our new affiliate program
        <i class="fal fa-long-arrow-right ml-1" />
      </a>
      <div class="bg-primary h-1" />
      <div class="header__content pt-8 pb-8 pl-6 pr-6 mx-auto flex text-base text-slight font-medium items-center">
        <a href="/">
          <img :src="trunkLogoPath" class="logo">
        </a>
        <div class="hidden md:block ml-5 flex-grow">
          <a
            v-for="(navItem, index) in navItems"
            :key="index"
            :href="navItem.anchor ? navItem.href + navItem.anchor : navItem.href"
            class="nav-item"
            @click="goToNavItem(navItem)"
          >
            {{ navItem.label }}
          </a>
        </div>
        <div class="hidden md:block">
          <div class="nav-item">
            <LeadCapture :are-signups-open="areSignupsOpen" variant="header" />
          </div>
          <a :href="routes.newSessionPath" class="nav-item">
            Login
          </a>
        </div>
        <div class="md:hidden flex flex-grow justify-end">
          <div class="w-6 h-6 cursor-pointer flex items-center justify-center" @click="isMenuVisible = !isMenuVisible">
            <i v-if="!isMenuVisible" class="far fa-bars fa-2x cursor-pointer" />
            <i v-if="isMenuVisible" class="far fa-times fa-2x cursor-pointer" />
          </div>
        </div>
      </div>
      <div ref="menu" :class="['menu bg-white fixed left-0 right-0 z-10', { 'bottom-0': isMenuVisible }]">
        <div v-if="isMenuContentVisible" class="menu__content text-center px-4">
          <a
            v-for="(navItem, index) in navItems"
            :key="index"
            :href="navItem.href"
            class="nav-item"
          >
            {{ navItem.label }}
          </a>
          <div class="nav-item">
            <LeadCapture variant="mobile header" @close-modal="isMenuVisible = false" />
          </div>
          <a :href="routes.newSessionPath" class="nav-item">
            Login
          </a>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
  import VueScrollTo from 'vue-scrollto'
  import LeadCapture from '@application/components/lead-capture.vue'

  import trunkLogoPath from '@application/images/trunk-logo.svg'

  export default {
    components: {
      LeadCapture,
    },

    props: {
      areSignupsOpen: Boolean,
    },

    mounted() {
      // Account for the header height when needing to scroll to anchors and not have the header cover it up
      document.documentElement.setAttribute('style', `scroll-padding-top: ${this.$refs.header.clientHeight}px`)
    },

    data() {
      return {
        isMenuVisible: false,
        isMenuContentVisible: false,
        routes: TRUNK_ROUTES,
        navItems: [
          { label: 'Testimonials', href: TRUNK_ROUTES.testimonialsPath },
          { label: 'Pricing',      href: TRUNK_ROUTES.pricingPath },
          { label: 'FAQ',          href: '/', anchor: '#faq', isTracked: true },
          { label: 'About',        href: TRUNK_ROUTES.aboutPath },
          { label: 'Contact',      href: TRUNK_ROUTES.contactPath },
        ],
      }
    },

    computed: {
      trunkLogoPath() {
        return trunkLogoPath
      },
    },

    watch: {
      isMenuVisible(value) {
        this.isMenuContentVisible = value
      },
    },

    methods: {
      goToNavItem(navItem) {
        // Smooth scroll to anchor if present
        if (navItem.anchor) {
          // Offset neaded to clear the header
          VueScrollTo.scrollTo(navItem.anchor, { offset: -this.$refs.header.clientHeight })

        // Otherwise open URL in same window as usual
        } else {
          window.location.replace(navItem.href)
        }

        // Track click if enabled
        if (navItem.isTracked) {
          window.analytics.track(`Nav ${navItem.label} Clicked`)
        }

        return false
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "~@application/styles/config";

  .header__content {
    max-width: 1600px;
    height: $header-height;
  }

  .logo {
    height: 28px;
  }

  .menu {
    top: $header-height !important;
    bottom: 100%;

    &__content {
      .nav-item {
        @apply block my-4;
      }
    }
  }

  .nav-item {
    @apply block;

    @screen md {
      @apply inline-block mx-3;

      &:last-child {
        @apply mr-0;
      }
    }
  }

  a:hover:not(.btn) {
    @apply text-primary;
  }
</style>
